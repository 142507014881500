import React from 'react';
import './App.css';
import "@fontsource/mulish";
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/900.css"
import {
  Route, BrowserRouter as Router, Routes, Navigate, BrowserRouter,
} from 'react-router-dom';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { Layout } from './layout';
import { Assessment, AssessmentList } from './pages/Assessment';
import { UserSurvey } from './pages/UserSurvey';
import { Company } from './pages/Company';

function App() {
  
  
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<AssessmentList />} />
          <Route path='/assessment/:id' element={<Assessment />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/user_survey/:aId/:sId/:uId' element={<UserSurvey />} />
          <Route path='/company' element={<Company />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
